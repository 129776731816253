$env: qa;
@import '@/styles/_component-base.styles.scss';

.heroImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 67%;

    @include breakpoint(lg) {
        background-position: center center;
    }

    @include responsive-dpi-imgix('/homepage/hero', 'hero-v1', 375, 768, 1440);

    &V1 {
        @include responsive-dpi-imgix('/homepage/hero-v1', 'hero-v1', 400, 768, 1440);

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background: linear-gradient(to bottom, #4caedd, rgba(76, 174, 221, 0) 50%);

            @include breakpoint(md) {
                opacity: 0.1;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 95%);
            }
        }
    }
}
